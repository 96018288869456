@import '~antd/dist/antd.less';

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.ant-menu-inline-collapsed {
    width: 50px;
}

.collapse-toggle {
    margin-right: 16px;
    color: white;
    font-size: 20px;
    line-height: 64px;
    cursor: pointer;
}

.collapse-toggle:hover {
    color: #1890ff;
}

.iconbutton {
    color: white;
}

.iconbutton:hover {
    color: #1890ff;
    background-color: rgba(230, 230, 230, 200);
    border-radius: 50%;
}

.iconbutton:active {
    background-color: rgba(200, 200, 200, 200);
    border-radius: 50%;
}

.avatar-uploader > .ant-upload {
    width: 185px;
    height: 185px;
}

.span-click {
    cursor: pointer;
    color: #1890ff;
}

.span-click2 {
    cursor: pointer
}

.ant-table td { white-space: nowrap; }

.my .ant-input-number-input {
    text-align: right;
    padding-right: 30px;
}
@primary-color: #a61d24;